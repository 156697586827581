/* eslint-disable react/prop-types */
import React, { useEffect, Suspense } from 'react';
import 'shared/styles/main.module.scss';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Router } from 'react-router';
import RootContainer from 'routes/root/RootContainer';
import AppSdkContainer from 'routes/appSdk/AppSdkContainer';

import ErrorMessage from 'shared/components/AppMessage/ErrorMessage';

import LeagueMessagesContainer from 'routes/leagueMessages/containers/LeagueMessagesContainer';
import LeagueMembersContainer from 'routes/leagueMembers/containers/LeagueMembersContainer';
import RouteNotFound from 'shared/components/RouteNotFound';

// non-lazy loading for the pod
import LeagueMembersListContainer from 'routes/leagueMembers/containers/LeagueMembersListContainer';
import DetailedMemberViewContainer from 'routes/leagueMembers/containers/DetailedMemberViewContainer';
import ListEmailsContainer from 'routes/leagueMessages/list_emails/containers/ListEmailsContainer';
import ClubEmailContainer from 'routes/leagueMessages/club_email/containers/ClubEmailContainer';
import ViewEmailContainer from 'routes/leagueMessages/view_email/containers/ViewEmailContainer';
import LeagueRosterContainer from 'routes/leagueRostering/containers/LeagueRosterContainer';
import PageDashboardContainer from 'routes/leagueDashboard/PageDashboardContainer';
import SafetyTabContainer from 'routes/leagueSafety/containers/SafetyTabContainer';
import SafetyContainer from 'routes/leagueSafety/containers/SafetyContainer';
import QuestionnaireEditListContainer from 'routes/leagueSafety/containers/QuestionnaireEditListContainer';

// Container imports for lazy loading
// const LeagueMembersListContainer = React.lazy(() =>
//   import("routes/leagueMembers/containers/LeagueMembersListContainer")
// );
// const DetailedMemberViewContainer = React.lazy(() =>
//   import("routes/leagueMembers/containers/DetailedMemberViewContainer")
// );
// const ListEmailsContainer = React.lazy(() =>
//   import("routes/leagueMessages/list_emails/containers/ListEmailsContainer")
// );
// const ClubEmailContainer = React.lazy(() =>
//   import("routes/leagueMessages/club_email/containers/ClubEmailContainer")
// );
// const ViewEmailContainer = React.lazy(() =>
//   import("routes/leagueMessages/view_email/containers/ViewEmailContainer")
// );
// const LeagueRosterContainer = React.lazy(() =>
//   import("routes/leagueRostering/containers/LeagueRosterContainer")
// );
// const PageDashboardContainer = React.lazy(() =>
//   import("routes/leagueDashboard/PageDashboardContainer")
// );

export default function App({ store, history }) {
  return (
    <Provider store={ store }>
      <Sentry.ErrorBoundary
        beforeCapture={ (scope) => {
          scope.setTag('error_boundary', 'index');
        } }
        fallback={ <ErrorMessage message="Oops, something went wrong!" /> }
      >
        <Router history={ history }>
          <div>
            <Switch>
              <Route path="/:divisionId" render={ (props) => <AppWrapper { ...props.match } store={ store } /> } />
            </Switch>
          </div>
        </Router>
      </Sentry.ErrorBoundary>
    </Provider>
  );
}

function AppWrapper({ params, store }) {
  const { path } = useRouteMatch();
  console.log('%c RENDERED WITH SEASON-FRONTEND ', 'background: #000; color: #0F0');
  return (
    <div>
      { /* shows feedback, modals, and header in dev mode, and dispatches location info */ }
      <RootContainer params={ { divisionId: params.divisionId } }>
        <Switch>
          { /* <Suspense fallback={<AppLoading />}> */ }
          <Route exact path={ `${path}/dashboard` }>
            <Dashboard divisionId={ params.divisionId } />
          </Route>
          <Route path={ `${path}/league_members` }>
            <AppSdkContainer params={ { divisionid: params.divisionId } }>
              <MembersTab />
            </AppSdkContainer>
          </Route>
          <Route path={ `${path}/safety` }>
            <AppSdkContainer params={ { divisionid: params.divisionId } }>
              <SafetyTab />
            </AppSdkContainer>
          </Route>
          <Route path={ `${path}/league_rostering` }>
            <AppSdkContainer params={ { divisionid: params.divisionId } }>
              <LeagueRosterContainer />
            </AppSdkContainer>
          </Route>
          <Route path={ `${path}/league_messages` }>
            <AppSdkContainer params={ { divisionid: params.divisionId } }>
              <MessagesTab params={ params } />
            </AppSdkContainer>
          </Route>
          <Route component={ RouteNotFound } />
        </Switch>
        { /* </Suspense> */ }
      </RootContainer>
    </div>
  );
}

function Dashboard(props) {
  return (
    <div>
      <PageDashboardContainer divisionId={ props.divisionId } />
    </div>
  );
}

function MessagesTab({ params, store }) {
  const { path } = useRouteMatch();
  return (
    <div>
      <LeagueMessagesContainer>
        <Switch>
          <Route exact path={ `${path}/list_emails` } render={ (props) => <ListEmailsContainer { ...props } /> } />
          <Route exact path={ `${path}/club_email` } render={ (props) => <ClubEmailContainer { ...props } /> } />
          <Route exact path={ `${path}/club_email/:id` } render={ (props) => <ClubEmailContainer { ...props.match } /> } />
          <Route exact path={ `${path}/view_email/:id` } render={ (props) => <ViewEmailContainer { ...props.match } /> } />
          <Redirect exact from={ `${path}/` } to={ `${path}/list_emails` } />
        </Switch>
      </LeagueMessagesContainer>
    </div>
  );
}

function MembersTab() {
  const { path } = useRouteMatch();
  return (
    <div>
      <LeagueMembersContainer>
        <Switch>
          <Route
            exact
            path={ `${path}/list` }
            render={ (props) => <LeagueMembersListContainer divisionId={ props.divisionId } /> }
          />
          <Route
            exact
            path={ `${path}/:programMemberId` }
            render={ (props) => <DetailedMemberViewContainer { ...props.match } /> }
          />
          <Redirect exact from={ `${path}/` } to={ `${path}/list` } />
        </Switch>
      </LeagueMembersContainer>
    </div>
  );
}

function SafetyTab() {
  const { path } = useRouteMatch();
  return (
    <div>
      <SafetyContainer>
        <Switch>
          <Route exact path={ `${path}` } render={ (props) => <SafetyTabContainer divisionId={ props.divisionId } /> } />
          <Route
            exact
            path={ `${path}/questions_edit` }
            render={ (props) => <QuestionnaireEditListContainer { ...props.match } /> }
          />
        </Switch>
      </SafetyContainer>
    </div>
  );
}
