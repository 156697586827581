import React from 'react';
import { Panel, PanelBody, Grid, Cell } from '@teamsnap/teamsnap-ui';
import paymentsImg from './ad-payments.jpg';

const WidgetPayments = () => (
  <Panel mods="u-spaceBottomNone" style={ { height: '100%' } }>
    <PanelBody mods="u-padLg">
      <Grid>
        <Cell mods="u-size7of12 u-textCenter">
          <img src={ paymentsImg } alt="Learn to manage your Organization’s Payments" />
        </Cell>
        <Cell mods="u-size5of12 u-padRightLg u-padLeftLg">
          <h3>
            Learn to Manage Your <br />
            Organization’s Payments
          </h3>
          <p className="u-spaceTopSm u-spaceBottomSm">Get paid upfront, on time and directly into your bank account.</p>
          <p className="u-spaceBottomMd">
            Leverage invoicing and registration to manage your organization's finances with ease.
          </p>
          <a
            className="Button Button--primary"
            target="_blank"
            rel="noopener noreferrer"
            href="https://helpme.teamsnap.com/article/597-invoicing-overview"
          >
            Learn More About Payments
          </a>
        </Cell>
      </Grid>
    </PanelBody>
  </Panel>
);

export default WidgetPayments;
