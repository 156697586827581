import React from 'react';
import { Panel, PanelBody, Grid, Cell } from '@teamsnap/teamsnap-ui';
import laptopImg from './ad-laptop.jpg';

const WidgetWebsite = () => (
  <Panel mods="u-spaceBottomNone" style={ { height: '100%' } }>
    <PanelBody mods="u-padLg">
      <Grid>
        <Cell mods="u-size7of12 u-textCenter">
          <img src={ laptopImg } alt="Ask About My Website" />
        </Cell>
        <Cell mods="u-size5of12 u-flexAlignSelfCenter u-padRightLg">
          <h3>Need a Website?</h3>
          <p className="u-spaceTopSm u-spaceBottomSm">
            Quickly and easily build a beautiful club or league website. Just select one of our professional templates,
            drag and drop your page elements and you’re up and running!
          </p>
          <p className="u-spaceBottomMd">
            They’re easy to make, they look great and they integrate with your TeamSnap account.
          </p>
          <a className="Button Button--primary" href="mailto:csm@teamsnap.com">
            Ask About a Website
          </a>
        </Cell>
      </Grid>
    </PanelBody>
  </Panel>
);

export default WidgetWebsite;
