import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Panel } from 'shared/toolkit';

import AppLoading from 'shared/components/AppLoading';
import { ImgRedXLarge } from 'shared/components/Img';
import AppMessageContainer from 'shared/components/AppMessage/AppMessageContainer';

import { linkLeagueMessagesListEmails } from 'utils/links';

import ClubEmailAttachmentsContainer from '../containers/ClubEmailAttachmentsContainer';

import SavingEmailModal from './SavingEmailModal';
import ClubEmailRecipientList from './ClubEmailRecipientList';
import ClubEmailNoEmails from './ClubEmailNoEmails';

import styles from './ClubEmail.module.scss';

class ClubEmail extends Component {
  static propTypes = {
    params: PropTypes.shape({ id: PropTypes.string }),
    teamNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    division: PropTypes.shape({ id: PropTypes.number }).isRequired,
    divisionsByParentId: PropTypes.shape({}).isRequired,
    loggedInMember: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool.isRequired,
    memberEmailAddresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    loadingDivisionTeamNames: PropTypes.bool.isRequired,
    broadcastEmail: PropTypes.shape({}),
    selectedRecipients: PropTypes.shape({}).isRequired,
    modalMessage: PropTypes.string.isRequired,
    showSavingModal: PropTypes.bool.isRequired,
    saveEmail: PropTypes.func.isRequired,
    setSubject: PropTypes.func.isRequired,
    setBody: PropTypes.func.isRequired,
    setFromEmailAddress: PropTypes.func.isRequired,
    toggleRecipients: PropTypes.func.isRequired,
    setAppError: PropTypes.func.isRequired,
    clearAppMessages: PropTypes.func.isRequired,
    attachments: PropTypes.array,
    initClubEmail: PropTypes.func.isRequired,
    divisionLeaves: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    teamNamesByDivisionId: PropTypes.func.isRequired,
  };

  selectedAnyRecipients() {
    const { selectedRecipients } = this.props;

    if (Object.keys(selectedRecipients).length > 0) {
      return true;
    }
    return false;
  }

  validateEmail(isDraft) {
    const {
      broadcastEmail: { subject, body },
      setAppError,
      clearAppMessages,
    } = this.props;
    let validationMessage = '';

    if (subject.length < 1) {
      validationMessage += 'You must enter a subject for the email. ';
    }
    if (body.length < 1) {
      validationMessage += "You didn't enter any text for your email. ";
    }
    if (!isDraft && !this.selectedAnyRecipients()) {
      validationMessage += 'You must specify at least one recipient';
    }

    if (validationMessage.length > 0) {
      setAppError(validationMessage, true);
      return false;
    }
    clearAppMessages();
    return true;
  }

  onSendEmail = () => {
    this.submitEmail(false);
  };

  onSaveDraft = () => {
    this.submitEmail(true);
  };

  submitEmail = isDraft => {
    if (this.validateEmail(isDraft)) {
      this.props.saveEmail({ draft: isDraft });
    }
  };

  onSubjectChange = event => {
    this.props.setSubject(event.target.value);
  };

  onBodyChange = event => {
    this.props.setBody(event.target.getContent());
  };

  onFromEmailChange = event => {
    event.preventDefault();
    this.props.setFromEmailAddress(event.target.value);
  };

  UNSAFE_componentWillMount() {
    // if there is no id create an email. if there is, open the draft.
    this.props.initClubEmail(this.props.params && this.props.params.id ? this.props.params.id : null);
  }

  renderClubEmailFooter = () => (
    <div className={ styles.clubEmailFooter }>
      <Link to={ linkLeagueMessagesListEmails(this.props.division.id) } className="button ss-delete">
        <ImgRedXLarge />
        Cancel
      </Link>
      <Button type="button" className="Button" handleClick={ this.onSaveDraft } text="Save as Draft" iconLeft="edit" />
      <Button
        type="button"
        className="ButtonPrimary"
        handleClick={ this.onSendEmail }
        text="Send Email"
        iconLeft="mail"
      />
    </div>
  );

  render() {
    const {
      broadcastEmail,
      division,
      loading,
      loadingDivisionTeamNames,
      toggleRecipients,
      selectedRecipients,
      modalMessage,
      showSavingModal,
      attachments,
      loggedInMember,
      memberEmailAddresses,
      divisionsByParentId,
      divisionLeaves,
      teamNamesByDivisionId,
    } = this.props;

    if (loading || !division) {
      return <AppLoading loading />;
    }

    if (memberEmailAddresses.length <= 0) {
      return <ClubEmailNoEmails division={ division } member={ loggedInMember } />;
    }

    const { body, subject, fromEmailAddress } = broadcastEmail || {};

    return (
      <div>
        <div id="content">
          <AppMessageContainer />
          { showSavingModal && <SavingEmailModal title={ modalMessage } attachments={ attachments } /> }
          <form>
            <Panel
              title={ `Email ${division.name}` }
              componentStyles={ styles }
              footerComponent={ this.renderClubEmailFooter() }
            >
              <div className="u-padMd">
                <div className="FormGroup">
                  <label className="FormGroup-label">Subject:</label>
                  <input
                    id="subject"
                    name="subject"
                    type="text"
                    className="u-sizeFull"
                    value={ subject }
                    onChange={ this.onSubjectChange }
                  />
                </div>
                { memberEmailAddresses.length > 0 && (
                  <div className="FormGroup">
                    <label key="email_from" className="FormGroup-label">
                      Send Email From:
                    </label>
                    <select value={ fromEmailAddress } onChange={ this.onFromEmailChange } key="email_from_select">
                      { memberEmailAddresses.map(memberEmail => (
                        <option key={ memberEmail.id } value={ memberEmail.email }>
                          { memberEmail.email }
                        </option>
                      )) }
                    </select>
                  </div>
                ) }
                <div className={ ['FormGroup', styles.clubEmailEditor].join(' ') }>
                  <label className="FormGroup-label">Text of your email:</label>
                  <Editor
                    apiKey={ window.TeamSnap.tinyMceKey }
                    initialValue={ body }
                    onChange={ this.onBodyChange }
                    init={ {
                      menubar: false,
                      resize: false,
                      preview_styles: false,
                      relative_urls: false,
                      convert_urls: false,
                      height: '700',
                      statusbar: false,
                      content_style: 'body { font-size: 13px; }',
                      plugins: 'textpattern link image emoticons code lists hr table',
                      contextmenu: 'undo redo | cut copy paste',
                      toolbar_mode: 'wrap',
                      toolbar: `
                       undo redo | cut copy paste | code | formatselect | fontselect | fontsizeselect | bold italic underline strikethrough forecolor backcolor |
                       link unlink image emoticons| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent hr | table tabledelete |
                       tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol
                      `,
                    } }
                  />
                </div>

                <ClubEmailRecipientList
                  loading={ loadingDivisionTeamNames }
                  division={ division }
                  selectedRecipients={ selectedRecipients }
                  toggleRecipients={ toggleRecipients }
                  divisionsByParentId={ divisionsByParentId }
                  divisionLeaves={ divisionLeaves }
                  teamNamesByDivisionId={ teamNamesByDivisionId }
                />

                <hr className="Divider" />

                <ClubEmailAttachmentsContainer />
              </div>
            </Panel>
          </form>
        </div>
      </div>
    );
  }
}

export default ClubEmail;
